<template>
  <b-container class="p-0">
    <!-- privacy policy -->
    <b-row class="my-2">
      <b-col cols="12">
        <h1>Privacy Policy</h1>
        <b-card class="mt-1 mb-1">
          <b-row>
            <b-col>
              EFFECTIVE DATE: January 1, 2023<br>
              <br>
              This Privacy Policy applies to your interactions with DailySurvivorPool.com (DailySurvivorPool.com”), including, without limitation, your interaction with DailySurvivorPool.com at DailySurvivorPool.com or any other websites, applications, online services, mobile applications, widgets, or interactive features or downloads that post a link to this Privacy Policy, however accessed (“DailySurvivorPool.com Services”). This Privacy Policy explains our information collection practices, such as the types of information we collect and how we may use and share that information. Please review this Privacy Policy carefully. In addition, please review the Terms and Conditions, which governs your use of the DailySurvivorPool.com Services.<br>
              <br>
              By using the DailySurvivorPool.com website, the DailySurvivorPool.com Services, or by choosing to submit personal information to us, you signify your consent to all of the terms of this Privacy Policy, or that of your parent or legal guardian if you are under the age of majority in the jurisdiction in which you reside. If you do not agree with any terms of this Privacy Policy, please do not use this website or submit any personal information to DailySurvivorPool.com .<br>
              <br>
              <h2>Table of Contents</h2>
              <ol type="I">
                <li><a href="#item1">Overview</a></li>
                <li><a href="#item2">Information Collection</a>
                  <ol type="1">
                    <li><a href="#item3">Registration and Other Information You Provide</a></li>
                    <li><a href="#item4">Information Third Parties Provide About You</a></li>
                    <li><a href="#item5">Social Media/Integration With Third Party Platforms</a></li>
                    <li><a href="#item6">User Content</a></li>
                    <li><a href="#item7">Send To A Friend</a></li>
                    <li><a href="#item8">Information We Collect Automatically</a></li>
                    <li><a href="#item9">Cookies and Similar Technologies</a></li>
                  </ol></li>
                <li><a href="#item10">How We Use Information About You</a></li>
                <li><a href="#item11">Information Sharing</a></li>
                <li><a href="#item12">Third Party Ad Networks, Analytics Providers And Similar Third Parties</a></li>
                <li><a href="#item13">Updating Your Account</a></li>
                <li><a href="#item14">Third Party Content and Our Content Found Outside Of The DailySurvivorPool.com Services</a></li>
                <li><a href="#item15">Security</a></li>
                <li><a href="#item16">Consent To Transfer</a></li>
                <li><a href="#item17">Changes To This Privacy Policy</a></li>
                <li><a href="#item18">California Residents – Your California Privacy Right</a></li>
                <li><a href="#item19">Access and Rectification</a></li>
                <li><a href="#item20">Contact Us</a></li>
              </ol>

              <a name="item1" /><h2>OVERVIEW</h2>
              This summary of our Privacy Policy highlights some important points regarding collection and sharing of your information and third parties who may set cookies and collect information through DailySurvivorPool.com Services. Please review our full Privacy Policy below before using the DailySurvivorPool.com Services.<br>
              <br>
              Use of Information: We primarily use the information we collect through the DailySurvivorPool.com Services in connection with your use of the DailySurvivorPool.com Services and to communicate with you regarding sweepstakes and promotions, news, special offers and other information. Click here for more details.<br>
              Sharing of Information: We may share the information we collect through the DailySurvivorPool.com Services with our vendors and third party business and marketing partners, including companies who sponsor and offer sweepstakes, contests and similar promotions through the DailySurvivorPool.com Services. We may also share information about you for legal and business reasons. Click here for more details. If you are a California Resident, you may request additional information; please click here for more details.<br>
              Third Parties Collecting Information Through the Services: The DailySurvivorPool.com Services may contain content served by third parties, including videos and advertisements, and we work with third party service providers to serve advertisements on the DailySurvivorPool.com Services and elsewhere online. In connection with providing content and services, third parties may collect certain information about you and your visits to this or other web sites and we may receive information regarding the use of the DailySurvivorPool.com Services and the effectiveness of our advertisements. These advertising networks, advertisers, ad agencies, analytics service providers and other vendors may set and access their own tracking devices (including cookies and web beacons) and they may otherwise collect or have access to information about you and your online browsing activities. Click here for additional information, including certain opt-out choices available to you.<br>
              <br>
              <a name="item2" /><h2>INFORMATION COLLECTION</h2>
              <a name="item3" /><strong>1. Registration and Other Information You Provide</strong><br>
              <br>
              The DailySurvivorPool.com Services may collect "Personal Information" (information that identifies you as a specific individual, such as your full name or e-mail address), account information (password or other information that helps us confirm that it is you accessing your account) and demographic or other information (such as your ZIP code/Postal code and information about your interests and preferences). If we combine demographic or similar information with the Personal Information we collect, we will treat it as Personal Information under this Privacy Policy.<br>
              <br>
              The DailySurvivorPool.com Services are general audience services and are not intended for individuals younger than the age of 13. We do not knowingly collect Personal Information from individuals younger than the age of 13 through our online services. If you are the parent/legal guardian of a minor who has provided DailySurvivorPool.com with personal information, you may contact us using the contact information provided below to request that we delete such information.<br>
              <br>
              <a name="item4" /><strong>2. Information Third Parties Provide About You</strong><br>
              <br>
              You consent that we may receive information about you from third parties. We may combine this information in our database and we may use it to offer you products or services, or for similar purposes. We also may supplement the information we collect with outside records from third parties. If we combine the information we receive from others with information we collect through the DailySurvivorPool.com Services, we will apply this Privacy Policy to the combined information.<br>
              <br>
              <a name="item5" /><strong>3. Social Media/Integration With Third Party Platforms</strong><br>
              <br>
              The DailySurvivorPool.com Services may permit you to interact with a third party site or service, such as enabling you to "like" us on another site or "share" content from DailySurvivorPool.com Services with other sites (a “Social Networking Service”). If you choose to "like" or "share" content or to otherwise post information from or via the DailySurvivorPool.com Services to a Social Networking Service, that information may be publicly displayed, and the third party may have access to certain information about you and your use of the DailySurvivorPool.com Services. Similarly, if you interact with us through a Social Networking Service, we may have access to information about you (for example, if you "like" or "follow" our page on a third party site). Please note that if you mention or comment upon DailySurvivorPool.com or the DailySurvivorPool.com Services in your post to a third party platform, the third party platform may allow us to publish your post on the DailySurvivorPool.com Services.<br>
              <br>
              You may also choose to use a social media tool offered by a third party, but available on DailySurvivorPool.com Services (such as posting a review or logging into the DailySurvivorPool.com Services with your username and password for another site). Use of such a feature may allow us to collect (or the third party to share) information about you, including Personal Information. When you choose to use a social media tool available on a DailySurvivorPool.com Service, you may be opting to link your activities on the DailySurvivorPool.com Services with a third party site or service. That third party may collect information about your visits to the DailySurvivorPool.com Services and may publish that activity to your profile or similar feature on that third party site. The information we collect is subject to this Privacy Policy. The information collected and stored by the third party remains subject to the third party's privacy practices, including whether the third party continues to share information with us, the types of information shared, and your choices with regard to what is visible to others on that third party site.<br>
              <br>
              To manage the information DailySurvivorPool.com may receive about you from a Social Networking Service, you will need to follow the instructions provided by the Social Media Service. Once DailySurvivorPool.com receives your information from a Social Networking Service, that information is stored and used by DailySurvivorPool.com in accordance with this Privacy Policy.<br>
              <br>
              <a name="item6" /><strong>4. User Content</strong><br>
              <br>
              The DailySurvivorPool.com Services may provide you the opportunity to post content publicly through interactive features. You may choose, through such features or otherwise, to submit or post reviews, comments, photographs, audio files, video files, and suggestions related to the DailySurvivorPool.com Services or otherwise post free form comments (collectively, “User Content”). Please note that certain information, such as your name and state/province/territory of residence, and a link to any website URL you provide may be publicly displayed by the DailySurvivorPool.com Services along with your User Content. However, DailySurvivorPool.com will not publish your email address or other contact information with these comments.<br>
              <br>
              Note that any User Content you post is public – others will have access to your User Content and may read, see, use, and/or share it with third parties. If you choose to voluntarily disclose Personal Information in your User Content, that information may be viewed by the public, and DailySurvivorPool.com cannot control the manner in which third parties collect use or disclose such information. If you do not wish your Personal Information to be available in this manner, please do not include it in your User Content.<br>
              <br>
              If you submit User Content, it may be published online or offline (including through the DailySurvivorPool.com Services, on-air, in a DVD, or any other format currently existing or later developed). In addition, we may use your User Content and any excerpt from your User Content in connection with our advertising, marketing, publicity, and promotional activities. By publishing any User Content, you give DailySurvivorPool.com a world-wide, perpetual, irrevocable, transferable, sub-licensable, royalty-free, non-exclusive, and unrestricted license to copy, reproduce, adapt, transmit, edit, modify, or otherwise use, publicly display, distribute, translate and create compilations and derivative works from, any and all such User Content, and you waive all moral rights in and to such User Content in favour of DailySurvivorPool.com .<br>
              <br>
              <a name="item7" /><strong>5. Send To A Friend</strong><br>
              <br>
              Some features of the DailySurvivorPool.com Services may permit you to send a friend a communication from the DailySurvivorPool.com Services You may only refer friends and family from whom you have received permission to provide their email address to DailySurvivorPool.com . You may only refer friends with whom you have had a direct voluntary two way communications, and with whom who you have a personal relationship. You may only refer family members to whom you are related by, marriage, a common-law partnership, or parent-child relationship, and with whom you have had direct voluntary two way communications. The referred email addresses will only be used by DailySurvivorPool.com for the purpose of contacting your friend(s) or family, on your behalf, to provide information about DailySurvivorPool.com or a DailySurvivorPool.com promotion so they can sign up if they choose to do so and they will not be added to any direct marketing list or used in any direct marketing programs without their consent. If your friend or family member consents, this information may be used by DailySurvivorPool.com or disclosed to third parties to send marketing email messages consistent with the provisions of this Privacy Policy.<br>
              <br>
              <a name="item8" /><strong>6. Information We Collect Automatically</strong><br>
              <br>
              DailySurvivorPool.com , and our third party service providers (including analytics providers, advertising companies and third party content providers), may automatically collect certain "Usage Information" whenever you visit or interact with the DailySurvivorPool.com Services. "Usage Information" typically includes the browser and operating system you may be using, the URL or advertisement that referred you to the DailySurvivorPool.com Services (if applicable), the search terms you entered into a search engine that lead you to the DailySurvivorPool.com Services (if applicable), all of the areas within the DailySurvivorPool.com Services that you visit, and the time of day you used the DailySurvivorPool.com Services, among other information relating to your use of the DailySurvivorPool.com Services. We may use Usage Information for a variety of purposes, including analyzing and improving the DailySurvivorPool.com Services and products, to provide special offers, and to select appropriate content to display to you.<br>
              <br>
              In addition, we automatically collect your IP address, mobile device identifier or other unique identifier ("Device Identifier") for any computer, mobile phone or tablet, or other device you may use to access the DailySurvivorPool.com Services. A Device Identifier is a number that is automatically assigned or connected to the device you use to access a Service, and our servers identify your device by its Device Identifier. Some mobile service providers may also provide us or our third party service providers with information regarding the physical location of the device used to access a Service or you may agree to allow us to connect location information if you access the DailySurvivorPool.com Services through a mobile device. Usage Information is generally non-identifying, but if we associate it with you as a specific and identifiable person, we treat it as Personal Information. <br>
              <br>
              Note that your browser settings may allow you to automatically transmit a “Do Not Track” signal to websites and online services you visit. There is no consensus among industry participants as to what “Do Not Track” means in this context.  Like many websites and online services, DailySurvivorPool.com currently does not alter its practices when it receives a “Do Not Track” signal from a visitor’s browser. To find out more about “Do Not Track,” please visit http://www.allaboutdnt.com. <br>
              <br>
              <a name="item9" /><strong>7. Cookies and Similar Technologies</strong><br>
              <br>
              The DailySurvivorPool.com Services may include the following tracking technologies, and similar technologies, and which may be set and controlled by us or by our third party providers:<br>
              <br>
              Cookies: A cookie is a data file placed on your computer or other device when you visit the DailySurvivorPool.com Services or otherwise access our online content. Cookies may be used for many purposes, including, without limitation, remembering you and your preferences, tracking your visits to our web pages, and facilitating your check-out and purchases. We employ Adobe Flash cookies in certain situations where we use Flash to provide some content such as video clips or animation. We do not use Flash cookies to serve interest-based advertising. For more information about Flash and the privacy choices Adobe offers, visit their site here. If you choose to disable cookies or to adjust your Flash privacy settings, some features of the DailySurvivorPool.com Services may not function properly.<br>
              <br>
              Web Beacons: Web beacons are small graphic images or other web programming code that may be found within the DailySurvivorPool.com Services and our communications with you. Web beacons are used to recognize certain types of information on your computer such as cookies, the time and date a page is viewed, and a description of the page where the web beacon is placed. Web beacons or similar technologies may be used for a number of purposes, including, without limitation, to count visitors to the DailySurvivorPool.com Services, to monitor how users navigate the DailySurvivorPool.com Services, and to count whether an e-mail we sent was opened. Web beacons may be used to collect certain Personal Information (for example, the email address associated with an email message).<br>
              <br>
              Embedded Scripts: An Embedded Script is programming code that is designed to collect information about your interactions with the DailySurvivorPool.com Services, such as the links you click on. The code is temporarily downloaded onto your computer or other device and is deactivated or deleted when you disconnect from the DailySurvivorPool.com Services.<br>
              <br>
              <a name="item10" /><h2>HOW WE USE INFORMATION ABOUT YOU</h2>
              <br>
              We may use information about you, including Personal Information and Usage Information, to: (1) process a transaction, including your registration with the DailySurvivorPool.com Services and verifying information you provide is active and valid; (2) allow you to participate in sweepstakes, promotions and other features we offer or to provide related customer services (for example, to respond to your questions, complaints or comments); (3) tailor content, recommendations and offers we display to you on the DailySurvivorPool.com Services and elsewhere online; (4) send you information regarding DailySurvivorPool.com or information from us relating to a third party; (5) improve the DailySurvivorPool.com Services and our products, including asking you to participate in surveys, and for other internal business purposes; (6) contact you with regard to your use of the DailySurvivorPool.com Services including, in our discretion, changes to our policies; (7) administer customer rewards programs; and (8) as described in this Privacy Policy and for purposes disclosed at the time you provide your information or otherwise with your consent.<br>
              <br>
              <a name="item11" /><h2>INFORMATION SHARING</h2>
              <br>
              We may share non-Personal Information, such as aggregate user statistics, demographic information and Usage Information with third parties, including advertisers, content providers, and analytics providers, in our discretion and for a variety of purposes. We also may share the information we have collected about you, including Personal Information, as described in this Privacy Policy, including the following:<br>
              <br>
              Third Party Marketing Partners: We may share the Personal Information we collect through the DailySurvivorPool.com Services with our subsidiaries and affiliates, and with third party business and marketing partners, including companies who sponsor and offer sweepstakes, contests and similar promotions through the DailySurvivorPool.com Services. With your consent, those third parties may reach out to you for their own marketing purposes. California Residents: for information on how to request additional information about third parties with whom we share Personal Information for their marketing purposes, please click here. <br>
              <br>
              Sweepstakes, Contests And Promotions. We may work with third parties that sponsor or offer sweepstakes, contests, and other promotions. Individuals must create an account for the DailySurvivorPool.com Services in order to participate in sweepstakes, contests, and other promotions, and that account must remain active in order to participate. Unless you cancel your DailySurvivorPool.com Services account, that account will continue to remain active after the completion of any sweepstakes, contest or promotion. Sweepstakes, contests and other promotions also may each have a separate registration process that first requires you to create or log into your DailySurvivorPool.com account. By participating in a promotion, you are agreeing to the terms, conditions or official rules that govern that promotion, which may contain specific requirements of you, including, except where prohibited by law, allowing us and the sponsor(s) of the promotion to use your name, voice, likeness or other indicia of persona in advertising or marketing. If you choose to enter a promotion, Personal Information may be disclosed to third parties or the public in connection with the administration of such promotion, including, without limitation, in connection with winner selection, prize fulfillment, and as required by law or permitted by the promotion's official rules, such as on a winners list.<br>
              <br>
              Third Parties Providing Services On Our Behalf. We may provide third parties with access to user information, including Personal Information, in order for them to perform certain services on behalf of us or you, such as fulfilling and processing contest entries, tracking analytics, sending communications on our behalf or performing other administrative services.<br>
              <br>
              Administrative And Legal Reasons. We may also transfer and disclose user information, including Personal Information, to third parties: (i) in the event we are required to respond to subpoenas or other legal process or if in our opinion such disclosure is required by law; (ii) at the request of governmental authorities conducting an investigation; or (iii) to protect and/or defend the Terms and Conditions or other policies applicable to the DailySurvivorPool.com Services or to protect the personal safety, rights, property or security of DailySurvivorPool.com Services or any individual. We may also use Device Identifiers, including IP addresses, to identify users, and may do so in cooperation with copyright owners, Internet service providers, wireless service providers or law enforcement agencies in our discretion.<br>
              <br>
              Business Transfers. We may share Personal Information with our parent, and with our subsidiaries and affiliated entities primarily for business and operational purposes. DailySurvivorPool.com Services reserves the right to disclose and transfer all information related to the Site, including Personal Information: (i) to a subsequent owner, co-owner or operator of the Sites or one of our databases; or (ii) in connection with a corporate merger, consolidation, restructuring, the sale of certain of our ownership interests, assets, or both, or other company change, including, without limitation, during the course of any due diligence process.<br>
              <br>
              <a name="item12" /><h2>THIRD PARTY AD NETWORKS, ANALYTICS PROVIDERS AND SIMILAR THIRD PARTIES</h2>
              <br>
              The DailySurvivorPool.com Services may contain content served by third parties, including videos and advertisements, and we may work with vendors (including network advertisers, ad agencies, and analytics companies) to provide us with information regarding traffic on the DailySurvivorPool.com Services, to serve our advertisements on the DailySurvivorPool.com Services and elsewhere online, and to provide us with information regarding the use of the DailySurvivorPool.com Services and the effectiveness of our advertisements. In connection with serving content and providing analytics and advertisement services, third parties may collect certain information about you and your visits to the DailySurvivorPool.com Services or other web sites. These third parties may set and access their own tracking technologies (including cookies and web beacons) [Link to those sections of this policy] and may otherwise collect or have access to your Device Identifier and information about your visits to and activity on the DailySurvivorPool.com Services, as well as other web sites or online services. Some of these third parties may collect Personal Information over time when you visit the Service or other websites and services.<br>
              <br>
              Cookies and web beacons, including those set by third party network advertisers, may be used to, among other things, target advertisements, prevent you from seeing the same advertisements too many times and to conduct research regarding the usefulness of certain advertisements to you. We may share Device Identifier and Usage Information about visitors with third party advertising companies, analytics providers and other vendors for similar purposes. While we may use a variety of service providers to perform advertising and analytics services, some of these companies are members of the Network Advertising Initiative ("NAI") or the Digital Advertising Alliance ("DAA") Self-Regulatory Program for Online Behavioral Advertising. You may want to visit http://www.networkadvertising.org/managing/opt_out.asp which provides information regarding targeted advertising and the "opt-out" procedures of NAI members. You may also want to visit or http://www.aboutads.info/choices/, which provides information regarding targeted advertising and offers an "opt-out" by participating companies in the DAA Self-Regulatory Program. Please note opting out through these mechanisms does not opt you out of being served advertising. You will continue to receive generic ads while online.<br>
              <br>
              <a name="item13" /><h2>UPDATING YOUR ACCOUNT</h2>
              <br>
              You are responsible for maintaining the accuracy of the information you submit to us. You may review and update the information you have provided to us through your account on the DailySurvivorPool.com Services. We will make good faith efforts to make requested changes in our then active databases. If you make changes, information may persist internally for administrative purposes and that residual data may remain on backup media or for other reasons. Note, however, that if you have made any public postings on or through the DailySurvivorPool.com Services, those posts usually will not be removed.<br>
              <br>
              The DailySurvivorPool.com Services may contain web pages or other features through which you may be able to unsubscribe or modify the e-mail messages or similar communications we send to you. You can choose to opt out of receiving marketing e-mails by following the opt-out instructions provided to you in those e-mails. Please note that we reserve the right to send you certain communications relating to your account or use of the DailySurvivorPool.com Services (for example, administrative and service announcements) and these transactional account messages may be unaffected if you opt-out from marketing communications.<br>
              <br>
              Users who access the DailySurvivorPool.com Services through a mobile device may, with permission, receive push notifications. Notification preferences can be modified in the setting menu for the mobile application. If you sign up to receive SMS or MMS messages that may be offered through the DailySurvivorPool.com Services, you may unsubscribe from any text messages by replying “STOP.”<br>
              <br>
              <a name="item14" /><h2>THIRD PARTY CONTENT AND OUR CONTENT FOUND OUTSIDE OF THE DAILYSURVIVORPOOL.COM CONNECT SERVICES</h2>
              <br>
              Certain content on the DailySurvivorPool.com Services may be hosted and served by third parties and DailySurvivorPool.com , and the DailySurvivorPool.com Services may contain links to third party web sites that DailySurvivorPool.com does not control. For example, the DailySurvivorPool.com Services may provide links to third party sites through which you can purchase merchandise, products or services or which link to a product or service offered by a third party. The DailySurvivorPool.com Services may also contain certain third party applications, such as email, web browser toolbars, wallpapers or other downloads or software applications. These links and applications may include those that use a DailySurvivorPool.com logo as part of a co-branding agreement. In addition, other DailySurvivorPool.com content, such as widgets, may be included on web pages, web sites, and mobile applications that are not associated with DailySurvivorPool.com and over which we have no control. These third parties may collect Site Usage Information through the use of their own cookies and web beacons, independently collect data or solicit Personal Information, and may have the ability to track your use of their sites and services.<br>
              <br>
              Certain content you see on the DailySurvivorPool.com Services is provided by third parties and the DailySurvivorPool.com Services may link to third party web sites, online services or other content. In addition, you may see DailySurvivorPool.com content on third party web sites or elsewhere online. These third parties may independently collect data. We are not responsible for the privacy practices or business practices of any third party.<br>
              <br>
              <a name="item15" /><h2>SECURITY</h2>
              <br>
              We take commercially reasonable security measures, including technological, organizational and physical measures, to protect the Personal Information submitted to us, both during transmission and once we receive it. However, no method of electronic transmission or storage is 100% secure. Therefore, we cannot guarantee the security of any information you submit and you do so at your own initiative and risk.<br>
              <br>
              Personal Information may only be accessed by persons within our organization, or our third party service providers, who require such access to provide you with the services indicated above.<br>
              <br>
              <a name="item16" /><h2>CONSENT TO TRANSFER</h2>
              <br>
              DailySurvivorPool.com is a United States company. If you are located outside of the United States, please be aware that information we collect will be transferred to and processed in the United States. By using the DailySurvivorPool.com Services, or providing us with any information, you fully understand and unambiguously consent to this transfer, processing and storage of your information in the United States, a jurisdiction in which the privacy laws may not be as comprehensive as those in the country where you reside and/or are a citizen. As a result, transferred information may be subject to access requests from governments, courts, or law enforcement in the United States according its laws.<br>
              <br>
              <a name="item17" /><h2>CHANGES TO THIS PRIVACY POLICY</h2>
              <br>
              We may update this Privacy Policy at any time and any changes will be effective upon posting. By continuing to access or use the DailySurvivorPool.com Services after those changes become effective, you (or your parent/legal guardian if you are under the age of majority in your jurisdiction of residence) agree to be bound by the revised Privacy Policy. In the event that there are material changes to the way we treat your Personal Information, we will provide notice through the DailySurvivorPool.com Services and we may also notify you by email or through other contact mechanisms we have for you in our sole discretion. Please check this Privacy Policy from time to time to ensure that you are aware of any changes. However, we will not make material changes to how we use your previously collected Personal Information, unless you consent to the new or revised policy or generally to such uses.<br>
              <br>
              <a name="item18" /><h2>CALIFORNIA RESIDENTS – YOUR CALIFORNIA PRIVACY RIGHTS</h2>
              <br>
              Under California Civil Code Section 1798.83, California residents who provide us with personal information in obtaining products or services for personal, family, or household use are entitled to request and obtain from us one time per calendar year information about the customer information we shared, if any, with other businesses for their own direct marketing uses. If applicable, this information would include the categories of customer information and the names and addresses of those businesses with which we shared customer information for the immediately prior calendar year.<br>
              <br>
              To obtain this information from us, please send your request to or to the following mailing address:<br>
              <br>
              You must put the statement "Your California Privacy Rights" in the subject field of your e-mail or include it in your writing if you choose to write to us at the designated mailing address. You must include your name, street address, city, state, and zip code. We will respond to you at your mailing address, or at our option, your e-mail address. We are not responsible for notices that are not labeled or sent properly, or do not have complete information.<br>
              <br>
              <a name="item19" /><h2>ACCESS AND RECTIFICATION</h2>
              <br>
              You have a right to request access to your Personal Information and to request a correction to it if you believe it is inaccurate. If you have submitted Personal Information to DailySurvivorPool.com and would like to have access to it, or if you would like to have it removed or corrected, please contact us using the contact information provided below. We will use reasonable efforts to comply with your request; however, in some cases we may not be able to allow you to access certain Personal Information in certain circumstances, for example if it contains Personal Information of other persons, or for legal reasons. In such cases, we will provide you with a reasonable explanation of why it is not possible to grant access to your Personal Information. You may also request information regarding the third parties to whom your information may have been disclosed.<br>
              <br>
              <a name="item20" /><h2>CONTACT US</h2>
              <br>
              If you have questions or comments about this privacy policy, you can contact our Privacy Officer at admin@DailySurvivorPool.com.<br>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BContainer, BRow, BCol,
  BCard,
} from 'bootstrap-vue'

import HomeBackground from '@/assets/images/backgrounds/home.png'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BCard,
  },
  data() {
    return {}
  },
  created() {
    document.body.style.backgroundImage = `url(${HomeBackground})`
  },
}
</script>

<style scoped>
.transparent {
  opacity: 0.2;
}
</style>
